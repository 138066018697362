import { useEffect, useState } from "react";
import { withPrefix } from "gatsby";

const MAGENTO_URL = process.env.GATSBY_MAGENTO_URL;
export const hasMagento = !!MAGENTO_URL;
export const MAGENTO_CART_URL = `${MAGENTO_URL}${withPrefix(`/checkout/cart`)}`;
const fetchMagentoGraphql = async (query, variables = {}) => {
  const prefix = withPrefix("/").slice(1, -1);
  const response = await fetch(withPrefix("/graphql"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Store": prefix,
    },
    body: JSON.stringify({ query, variables }),
  });
  if (!response.ok) throw new Error(response.statusText);
  const { data } = await response.json();
  // console.log('data: ', data);
  return data;
};
export const createCart = async () => {
  const data = await fetchMagentoGraphql(`
    mutation {
      createEmptyCart
    }`);
  return data.createEmptyCart;
};
export const requestUserCart = async (userData, guestCartId) => {
  const prefix = withPrefix("/").slice(1, -1);
  const response = await fetch(`/headless-api/magento/user-cart`, {
    method: "POST",
    body: JSON.stringify({
      prefix,
      firstname: userData.given_name,
      lastname: userData.family_name,
      email: userData.email,
      cartId: guestCartId,
    }),
  });
  if (!response.ok) throw new Error(response.statusText);
  const data = await response.json();
  console.log("data: ", data);
  return data.cart;
};

const normalizeProduct = (magentoProduct) => {
  const NUMBER_REGEX = new RegExp(`^[0-9]+$`);
  const isExtraServiceSpecialPriceZero =
    magentoProduct.special_price === 0 && !NUMBER_REGEX.test(magentoProduct.sku);

  return {
    id: magentoProduct.sku,
    sku: magentoProduct.sku,
    name: magentoProduct.name,
    descriptionHtml: magentoProduct.description?.html,
    availableForSale: magentoProduct.stock_status === "IN_STOCK",
    msrpPrice: magentoProduct?.haier_msrp
      ? {
          value: parseFloat(magentoProduct.haier_msrp),
          currency: magentoProduct.price_range.minimum_price.regular_price.currency,
        }
      : magentoProduct?.price_range?.minimum_price.regular_price,
    sellingPrice:
      magentoProduct.price_range?.minimum_price?.regular_price ||
      magentoProduct.special_price ||
      isExtraServiceSpecialPriceZero
        ? {
            value: isExtraServiceSpecialPriceZero
              ? 0
              : magentoProduct.special_price ||
                magentoProduct.price_range.minimum_price.regular_price.value,
            currency: magentoProduct.price_range.minimum_price.regular_price.currency,
          }
        : null,
    omnibusPrice: magentoProduct?.haier_msrp
      ? magentoProduct.price_range.minimum_price.regular_price
      : null,
    // magento only
    extra_services: magentoProduct.extra_services,
    special_from_date: magentoProduct.special_from_date,
    special_to_date: magentoProduct.special_to_date,
    contributo_raee: magentoProduct.contributo_raee,
    items: magentoProduct.items,
  };
};

const normalizeCart = (cart) => {
  return {
    ...cart,
    items: cart?.items?.filter(Boolean)?.map((item) => ({
      ...item,
      product: normalizeProduct(item.product),
    })),
  };
};

export const addProductToCart = async (productId, cartId, userLogged) => {
  if (userLogged) {
    const prefix = withPrefix("/").slice(1, -1);
    const response = await fetch(`/headless-api/magento/add-item`, {
      method: "POST",
      body: JSON.stringify({
        prefix,
        cartId,
        productId,
      }),
    });
    if (!response.ok) throw new Error(response.statusText);
    const data = await response.json();
    console.log("data: ", data);
    return data;
  } else {
    const data = await fetchMagentoGraphql(
      `
      mutation AddToCart($cartId: String!, $productId: String!, $quantity: Float!) {
        addProductsToCart(
          cartId: $cartId
          cartItems: [
            {
              quantity: $quantity
              sku: $productId
            }
          ]
        ){
          cart {
            id
            items {
              id
              quantity
              product {
                name
                sku
              }
            }
          }
        }
      }`,
      { cartId, productId, quantity: 1 }
    );
    console.log("data user not logged: ", data);
    return { ...data.addProductsToCart, cart: normalizeCart(data.addProductsToCart.cart) };
  }
};

export const addProductToCartWashpass = async (productId, cartId, selected_options, userLogged) => {
  if (userLogged) {
    const prefix = withPrefix("/").slice(1, -1);
    const response = await fetch(`/headless-api/magento/add-item`, {
      method: "POST",
      body: JSON.stringify({
        prefix,
        cartId,
        productId,
        selected_options,
      }),
    });
    if (!response.ok) throw new Error(response.statusText);
    const data = await response.json();
    console.log("data: ", data);
    return data;
  } else {
    const data = await fetchMagentoGraphql(
      `
      mutation AddToCart($cartId: String!, $productId: String!, $quantity: Float!, $selected_options: [ID!]) {
        addProductsToCart(
          cartId: $cartId
          cartItems: [
            {
              quantity: $quantity
              sku: $productId
              selected_options: $selected_options
            }
          ]
        ){
          cart {
            id
            items {
              id
              quantity
              product {
                name
                sku
              }
              ... on BundleCartItem {
                bundle_options {
                  uid
                  label
                  type
                  values {
                    id
                    label
                    price
                    quantity
                  }
                }
              }
            }
          }
        }
      }`,
      { cartId, productId, quantity: 1, selected_options }
    );
    console.log("data user not logged: ", data);
    return data.addProductsToCart;
  }
};

export const queryCart = async (cartId) => {
  if (!cartId) return null;
  const { cart } = await fetchMagentoGraphql(
    `
    query CartQuery($cartId: String!){
      cart(cart_id: $cartId) {
        id
        items {
          id
          quantity
          product {
            name
            sku
          }
        }
      }
    }
  `,
    { cartId }
  );
  if (cart) {
    return normalizeCart(cart);
  } else {
    throw new Error("Carrello anonimo non valido");
  }
};

//#Source https://bit.ly/2neWfJ2
const splitChunks = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const queryProductsDetails = async (productIds) => {
  // Magento sembra faccia un cap a 20 risultati per volta
  //commentato contributo_raee (attenzione!!!!!!)
  const chunks = splitChunks(productIds, 20);
  const results = await Promise.all(
    chunks.map(async (chunk) => {
      const data = await fetchMagentoGraphql(
        `
      query ProductsQuery($productIds: [String!]!) {
        products(filter: { sku: { in: $productIds } }) {
          items {
            name
            sku
            description {
              html
            }
            stock_status
            extra_services
            haier_msrp
            price_range {
              minimum_price {
                regular_price {
                  currency
                  value
                }
              }
            }
            special_price
            special_from_date
            special_to_date
            contributo_raee
            ... on BundleProduct {
              dynamic_sku
              dynamic_price
              dynamic_weight
              price_view
              ship_bundle_items
              items {
                uid
                title
                required
                type
                position
                sku
                options {
                  uid
                  quantity
                  position
                  is_default
                  price
                  price_type
                  can_change_quantity
                  label
                  product {
                    uid
                    name
                    sku
                  }
                }
              }
            }
          }
        }
      }
    `,
        { productIds: chunk }
      );
      return data ? data.products.items : [];
    })
  );
  return results.flat().map((item) => normalizeProduct(item));
};

export const getCartUrl = async (cartId, userLogged) => {
  return userLogged ? MAGENTO_CART_URL : `${MAGENTO_CART_URL}?cartId=${cartId}`;
};

export const useCartUrl = (cart, userCartId) => {
  const [cartUrl, setCartUrl] = useState(null);
  useEffect(() => {
    const loadCartUrl = async () => {
      setCartUrl(cart?.id ? await getCartUrl(cart.id, !!userCartId) : null);
    };
    loadCartUrl();
  }, [cart, userCartId]);
  return cartUrl;
};

// export const queryWashpassProducts = async () => {
//   const data = await fetchMagentoGraphql(
//     `
//       query ProductsQuery {
//         products (filter: {category_id: {eq: "15"}}, pageSize: 25, sort: {name: DESC}) {
//           total_count
//           items {
//             name
//             sku
//             price_range {
//               minimum_price {
//                 regular_price {
//                   value
//                   currency
//                 }
//               }
//             }
//             #zuora_plan_id
//           }
//           page_info {
//             page_size
//             current_page
//           }
//         }
//       }
//     `
//   );
//   return data ? data?.products?.items?.flat() : [];
// };
