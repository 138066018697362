import Cookies from "js-cookie";
import { withPrefix } from "gatsby";

const getCookieName = () => {
  const pathPrefix = withPrefix("/").replace(/\//g, "").replace(/_/, "-").toLowerCase();
  return `ecommerce-cart-id-${pathPrefix}`;
};

const getCookieDomain = () => {
  const parentDomain = window.location.hostname
    .split(".")
    .reverse()
    .slice(0, 2)
    .reverse()
    .join(".");
  return parentDomain === "cloudfront.net" ? window.location.hostname : parentDomain;
};

export const saveCartId = (cartid) => {
  Cookies.set(getCookieName(), cartid, { domain: getCookieDomain() });
};

export const getCartId = () => Cookies.get(getCookieName());

export const removeCartId = () => {
  Cookies.remove(getCookieName(), { domain: getCookieDomain() });
};
