import React, { useCallback, useEffect, useMemo, useState, useContext } from "react";
import {
  createCart,
  queryCart,
  addProductToCartWashpass,
  getCartUrl,
  requestUserCart,
  useCartUrl,
} from "../utility/magento";
import UserContext from "./user";
import { productAddCartWashPass } from "../utility/datalayer";
import { isDirectAddToCart } from "../utility/campaign-manager";
import { getCartId, removeCartId, saveCartId } from "../utility/cookies-magento";
import CartContext from "./cart";

export const CartWashpassProvider = ({ children }) => {
  const { userData } = useContext(UserContext);

  const [loadingCart, setLoadingCart] = useState(true);
  const [guestCartId, setGuestCartId] = useState(null);
  const [cart, setCart] = useState(null);
  const [adding, setAdding] = useState(null);
  const [cartModalData, setCartModalData] = useState(null);
  const closeCartModal = useCallback(() => setCartModalData(null), []);

  const showCartWarning = useCallback((e) => {
    e.preventDefault();
    setCartModalData({
      msgKey: `noProductsInCart`,
      icon: false,
    });
  }, []);

  const loadUserCart = useCallback(async (cartId, userData) => {
    if (!userData) return;
    try {
      if (cartId) {
        removeCartId("magento");
      }
      const cart = await requestUserCart(userData, cartId);
      removeCartId("magento");
      setGuestCartId(null);
      setCart(cart);
    } catch (err) {
      //TODO gestire meglio
      console.error("Errore nel recupero del carrello utente: ", err);
      setLoadingCart(false);
      if (cartId) {
        saveCartId(cartId, "magento");
      }
    }
  }, []);

  const loadGuestCart = useCallback(async (cartId) => {
    setLoadingCart(true);
    try {
      const guestCart = await queryCart(cartId);
      setGuestCartId(guestCart.id);
      setCart(guestCart);
    } catch (err) {
      console.error("Errore nel recupero del carrello anonimo: ", err);
      console.warn("Carrello anonimo non valido, lo rimuovo");
      removeCartId("magento");
      setGuestCartId(null);
    }
    setLoadingCart(false);
  }, []);

  useEffect(() => {
    if (userData) {
      if (!cart) {
        loadUserCart(guestCartId || getCartId("magento"), userData);
      }
    } else if (userData === null) {
      if (!guestCartId) {
        const mgCartId = getCartId("magento");
        if (mgCartId) {
          loadGuestCart(mgCartId);
        } else {
          setCart(null);
          // console.log('User not logged and no guest cart yet, do nothing');
          setLoadingCart(false);
        }
      }
    } else {
      // console.log('Waiting for user data...');
      setLoadingCart(true);
    }
  }, [userData, loadUserCart, loadGuestCart, cart, guestCartId]);

  const userCartId = userData && cart ? cart.id : null;

  const addProduct = useCallback(
    async (product, priceData, location, position) => {
      if (loadingCart) return;
      const { key_prod: productKey, sku } = product;
      let productId = productKey || sku;

      setAdding(productId);

      setCartModalData({
        loading: true,
      });

      let error = false;
      let cartId = userCartId || guestCartId;
      // Creo il carrello anonimo se non esiste ancora
      let createdCartId = null;
      if (!cartId) {
        try {
          const newCartId = await createCart();
          saveCartId(newCartId, "magento");
          cartId = newCartId;
          createdCartId = newCartId;
        } catch (err) {
          console.error(`Errore nella creazione del carrello anonimo: `, err);
          error = true;
        }
      }
      const selected_options =
        product?.items?.map((item) => item.options.map((option) => option.uid))?.flat() || [];

      if (productKey) {
        console.error("Errore: prodotto non washpass ", productId, productKey);
        return;
      }
      // Procedo con l'aggiunta del prodotto
      try {
        const data = await addProductToCartWashpass(
          productId,
          cartId,
          selected_options,
          cartId === userCartId
        );
        setCart(data.cart);
        productAddCartWashPass(product, priceData, position);
      } catch (err) {
        console.error(`Errore nell'aggiunta al carrello di ${productId}: `, err);
        error = true;
        if (createdCartId) {
          setGuestCartId(createdCartId);
          setCart({ items: [] });
        }
      }
      setAdding(false);

      if (error) {
        setCartModalData({
          msgKey: `errorHasOccurred`,
          icon: "close",
        });
      } else {
        const cartUrl = await getCartUrl(cartId, cartId === userCartId);
        if (isDirectAddToCart(location) || (location === "code-validator" && sku)) {
          window.location.href = cartUrl;
        } else {
          setCartModalData({
            msgKey: `itemAddedToCart`,
            cartUrl,
          });
        }
      }
    },
    [guestCartId, userCartId, loadingCart]
  );

  useEffect(() => {
    if (cart) {
      setLoadingCart(false);
    }
  }, [cart]);

  const cartUrl = useCartUrl(cart, userCartId);

  const contextValue = useMemo(() => {
    return {
      cartId: guestCartId,
      addProduct,
      cart,
      cartUrl,
      adding,
      loadingCart,
      showCartWarning,
      cartModalData,
      closeCartModal,
      isWashpass: true,
    };
  }, [
    guestCartId,
    addProduct,
    cart,
    cartUrl,
    adding,
    loadingCart,
    showCartWarning,
    cartModalData,
    closeCartModal,
  ]);

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};
